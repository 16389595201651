import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/main/Main.js';
import Home from './components/home/Home.js';
import VideoOverview from './components/videos/Videos.js';

function App() {
  return (
      <BrowserRouter>      
        <Routes>
          <Route path="/" element={<Main />}> umstrukturieren, Main sollte nicht nötig sein
            <Route index element={<Home />} />
            <Route path="/videos" element={<VideoOverview />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
