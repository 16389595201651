import './Home.css';

function Home() {

  console.log('*** width', document.body.scrollWidth); 

  const getWrapperMargin = () => {
    const windowWidth = document.body.scrollWidth;
    const margin = Math.floor(windowWidth/100);
    return Math.min(margin, 8);

  }

  return (
    <div className="home-version-with-title">
      {/* <h1>Ein geheimnisvolles <br/> Weihnachtskonzert</h1> */}
      {/* <img className="home-img" src="img/concert-24-09-08-bild-und-titel.jpg"/> */}
      {/* <div>Am ersten Adventswochenende ist es wieder soweit: </div>
      <h2>Unser Weihnachtskonzert</h2> <div>diesmal mit einer kleinen Sherlock Holmes Geschichte</div> */}
      <img className="home-img-with-title" src="img/concert-24-09-08-bild-und-titel.jpg" alt="donkey with flowers"/>

      <div style={{margin: `0 ${getWrapperMargin()}rem`}}>
        <hr className="line"/>
        <p>Am 8.9. um 15:00 Uhr<br/>
        Einlass um 14:30 Uhr<br/>
        Rixdorfer Str. 51-53<br/>
        <a className='link' href='https://maps.app.goo.gl/1rXDdsRhiET6oAXn8'>Kapelle zu den 4 Aposteln</a></p>
        <hr className="line"/>
        <div>Für weitere Fragen wendet euch gern an konzert@maerklang.de</div>
      </div>
    </div>
  );
}

export default Home;