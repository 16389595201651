import './Videos.css';

function VideoOverview() {
  return (
    <div className="videos-overview">
      <h1>Video Übersicht</h1>
      <h2>Konzert-Stream vom 12.12.2021</h2>
      <video controls="controls" poster="/img/concert-21-12-12.png" width="80%">
        <source src="/movies/concert-21-12-12.mp4" type="video/mp4" />
      </video>
    </div>
  );
}

export default VideoOverview;